import styled from "styled-components"

const Container = styled.div`
    margin-left: auto;
    margin-right: auto;
    padding-left:2.5%;
    padding-right:2.5%;
    max-width:  ${({theme}) => theme.breakpoints.desktop};

    &:before, &:after {
        content: " ";
        display: table;
        grid-column-start: 1;
        grid-row-start: 1;
        grid-column-end: 2;
        grid-row-end: 2;
    }

    &:after {
        clear: both;
    }
`
export default Container