import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import { useSelector } from "react-redux"

import Ellipsis from "./ellipsis"
import { useAuth } from "../services/auth"
import { useTheme } from "../services/theme"
import { handleEventError } from "../services/error"
import { isEmptyObject, isIncognito, isMobile, getLocalStorageItem, setLocalStorageItem, removeLocalStorageItem } from "../services/helper"

import googleIcon from "../images/googleicon.svg"

const LOGGEDIN_PATH_CACHE_KEY = `loggedinPath`

const StyledButton = styled.button`
    padding-right: 24px;
    padding-left: 66px;
    padding-top: 13px;
    padding-bottom: 13px;
    border-style: solid;
    border-color: ${({theme}) => theme.colors.primary};
    border-radius: 25px;
    border-width: 1px;
    background-color: ${({theme}) => theme.colors.background};
    background-image: url(${googleIcon});
    background-position: 24px 11px;
    background-size: 18px 18px;
    background-repeat: no-repeat;
    color: ${({theme}) => theme.colors.primary};
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
    text-decoration: none;
    min-height: ${({minHeight}) => minHeight || "40px"};
    min-width: ${({minWidth}) => minWidth || "226px"};
    transition: transform 400ms cubic-bezier(.455, .03, .515, .955), box-shadow 200ms cubic-bezier(.455, .03, .515, .955);
    outline: none;
    margin-left: ${({marginLeft}) => marginLeft || "0px"};
    margin-right: ${({marginRight}) => marginRight || "0px"};

    &:hover {
    
        box-shadow: ${({theme}) => theme.hoverShadow};
        transform: translate(0px, -2px);
    }

    ${({breakpoint, responsiveStyle}) => breakpoint ? `@media (max-width: ${breakpoint}) {${responsiveStyle}}` : ""}
`


const GoogleButton = ({loggedInPath, newUserPath, autoRedirect, setAutoRedirect, waiting, setWaiting, show, children, ...rest}) => {
    const auth = useAuth()
    const theme = useTheme()
    const permissions = useSelector(state => state.permissions)
    const [redirectPaths, setRedirectPaths] = useState({})

    useEffect(() => {
        if(waiting) {
            return 
        }
        
        const loggedInPathCache = getLocalStorageItem(LOGGEDIN_PATH_CACHE_KEY)
        if(loggedInPathCache) {
            setRedirectPaths(JSON.parse(loggedInPathCache))
            removeLocalStorageItem(LOGGEDIN_PATH_CACHE_KEY)
            setWaiting(true)
        }
        
    },[waiting, setWaiting])

    useEffect(() => {

        if(!auth.isLoggedIn()) {
            return
        }

        if(autoRedirect) {
            navigate(loggedInPath || "/app/websites", { replace: true})
            return
        }


        if(waiting && !isEmptyObject(permissions)){
            setWaiting(false)

            const { redirectNewUser, redirectLoggedIn } = redirectPaths
            if(permissions.isNewUser) {
                navigate(newUserPath || redirectNewUser|| "/app/setup/personalize", { replace: true})
            } else {
                navigate(loggedInPath || redirectLoggedIn || "/app/websites", { replace: true})
            }
        }
    },[auth, permissions, redirectPaths, waiting, setWaiting, autoRedirect, loggedInPath, newUserPath])

    const handleGoogleSignin = async (e) => {
        let result
        try {
            e.preventDefault()
            if(await isIncognito()) {
                alert("Oops! It looks like you've gone Incognito. Try again without using Incognito mode.")
                return
            }
            if(setAutoRedirect) {
                setAutoRedirect(false)
            }
            setWaiting(true)
            if(!auth.isLoggedIn()) {
                if(isMobile()){
                    setLocalStorageItem(LOGGEDIN_PATH_CACHE_KEY, JSON.stringify({redirectNewUser: newUserPath, redirectLoggedIn: loggedInPath}))
                }
                result = await auth.signinWithGoogle()
            }
        } catch (err) {
            handleEventError(err, false, false)
        } finally {
            if(!result) {
                setWaiting(false)
                if(setAutoRedirect) {
                    setAutoRedirect(true)
                }
            }
        }
    }

    if(!show) {
        return null
    }

    return (
        <StyledButton onClick={ waiting ? null : handleGoogleSignin} {...rest}>
            { waiting ? <Ellipsis color={theme.colors.primary}/> : children }
        </StyledButton>
    )
}
export default GoogleButton