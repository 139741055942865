import React, {useState, useLayoutEffect} from "react"
import styled from "styled-components"

import { handleEventError } from "../services/error"

import Logo from "./logo"
import Container from "./container"

const StyledWrapper = styled.div`
    ${({shadow, theme}) => shadow ? `box-shadow: ${theme.shadow}` : ""};
    padding-top: 12.5px;
    padding-bottom: 12.5px;
    background-color: ${({theme}) => theme.colors.background};
    position: sticky; 
    top: 0; 
    left: 0;
    right: 0;
    z-index: 19;
`

const NavLeftWrapper = styled.div`
    position: relative;
    float: left;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`
const NavRightWrapper = styled.nav`
    position: relative;
    float: right;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    @media (max-width: ${({breakpoint, theme}) => breakpoint || theme.breakpoints.mobile}) {
        ${({responsivereverse}) => responsivereverse ? `flex-direction: row-reverse;` : ""}
    }
        
`
export const TopNavLeft = ({children, ...rest}) => (
    <NavLeftWrapper {...rest}>
        <Logo />
        {children}
    </NavLeftWrapper>
)

export const TopNavRight = ({children, ...rest}) => (
    <NavRightWrapper {...rest}>
        {children}
    </NavRightWrapper>
)

export default ({ children, shadow, ...rest }) => {
    const [showShadow, setShowShadow] = useState(shadow || false)

    useLayoutEffect(() => {
        try {
            if(shadow) {
                return 
             }
             
            const scrollHandler = () => {
                try {
                    if(window.scrollY > 0) {
                        setShowShadow(true)
                    } else {
                        setShowShadow(false)
                    }
                } catch (err) {
                    handleEventError(err, false, false)
                }

            }
            window.addEventListener("scroll", scrollHandler)
            return () => window.removeEventListener("scroll", scrollHandler)
        } catch (err) {
            handleEventError(err, false, false)
        }
    }, [shadow])

    return (
        <StyledWrapper shadow={showShadow} {...rest}>
            <Container>
                {children}        
            </Container>
        </StyledWrapper>
    )
}