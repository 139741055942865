import React from "react"
import styled from "styled-components"

const StyledMain = styled.main`
    min-height: calc(100vh - 65px);
    position: relative;
`
export default ({children}) => (
    <StyledMain>
        {children}
    </StyledMain>
)