import React, {useState} from "react" 
import styled from "styled-components"
import ResponsiveToggler from "./responsivetoggler"
import Burger from "./burger"

import { useTheme } from "../services/theme"

const StyledMenu = styled.nav`
    @media (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        display: none;
    }
`

const MobileMenu = styled.nav`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: ${({theme}) => theme.colors.modalBackground};
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    transition: transform 0.3s ease-in-out;
    width: 100%;
    z-index: 29;
    padding-top: 65px;
`

const StyledWrapper = styled.div`
    display: flex;
    align-items: center;
`


export default ({children, ...rest}) => {
    const [open, setOpen] = useState(false)
    const theme = useTheme()
    return (
        <StyledWrapper>
            <StyledMenu {...rest}>{children}</StyledMenu>
            <ResponsiveToggler responsiveonly="true" breakpoint={theme.breakpoints.tablet}>
                <Burger open={open} setOpen={setOpen}/>
                <MobileMenu open={open} aria-hidden={!open} {...rest}>{children}</MobileMenu>
            </ResponsiveToggler>
        </StyledWrapper>
    )
}
