import React from "react"
import styled, {keyframes} from "styled-components"

const bouncedelay = keyframes`
    0%, 80%, 100% { 
        transform: scale(0);
    } 40% { 
        transform: scale(1.0);
    }
`
const Dot1 = styled.div`
`
const Dot2 = styled.div`
`
const Dot3 = styled.div`
`

const DotsWrapper = styled.div`
    margin: 0px auto 0;
    width: 70px;
    text-align: center;

    & > div {
        width: 8px;
        height: 8px;
        background-color: ${({color, theme}) => color || theme.colors.inverseText};
        margin-left: 2px;
        margin-right: 2px;
        border-radius: 100%;
        display: inline-block;
        animation: ${bouncedelay} 1.4s infinite ease-in-out both;
    }

    & ${Dot1} {
        animation-delay: -0.32s;
    }

    & ${Dot2} {
        animation-delay: -0.16s;
    }

`
const Ellipsis = props => (
    <DotsWrapper {...props} >
        <Dot1></Dot1>
        <Dot2></Dot2>
        <Dot3></Dot3>
    </DotsWrapper>
)

export default Ellipsis