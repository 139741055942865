import styled from "styled-components"

export default styled.div`
    ${({position}) => position ? `position: ${position};` : ""}
    ${({top}) => top ? `top: ${top};` : ""}
    ${({bottom}) => bottom ? `bottom: ${bottom};` : ""}
    ${({left}) => left ? `left: ${left};` : ""}
    ${({right}) => right ? `right: ${right};` : ""}
    ${({display}) => display ? `display: ${display};` : ""}
    ${({minHeight}) => minHeight ? `min-height: ${minHeight};` : ""}
    ${({backgroundImage}) => backgroundImage ? `background-image: ${backgroundImage};` : ""}
    ${({backgroundPosition}) => backgroundPosition ? `background-position: ${backgroundPosition};` : ""}
    ${({backgroundSize}) => backgroundSize ? `background-size: ${backgroundSize};` : ""}
    ${({backgroundRepeat}) => backgroundRepeat ? `background-repeat: ${backgroundRepeat};` : ""}
    ${({breakpoint, responsiveStyle}) => breakpoint ? `@media (max-width: ${breakpoint}) {${responsiveStyle}}` : ""}
`