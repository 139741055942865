import React from "react"
import styled from "styled-components"

const StyledBurger = styled.button`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 40px;
    height: 40px;
    padding-top: 7.5px;
    padding-bottom: 7.5px;
    padding-left: 7.5px;
    padding-right: 7.5px;
    background: transparent;
    border: none;
    cursor: pointer;
    margin-left: 10px;
    position: relative;
    z-index: 39;

  &:focus {
    outline: none;
  }

  div {
    width: 25px;
    height: 3px;
    background: ${({ open, theme }) => open ? theme.colors.inverseBurger : theme.colors.burger};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }

    :nth-child(2) {
      opacity: ${({ open }) => open ? '0' : '1'};
    }

    :nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`

export default ({ open, setOpen, ...rest }) => ( 
    <StyledBurger aria-label="Toggle menu" aria-expanded={open} open={open} onClick={() => setOpen(!open)} {...rest}>
      <div />
      <div />
      <div />
    </StyledBurger>
)