import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"
import { useAuth } from "../services/auth" 
import { handleEventError } from "../services/error"
import PopupItem from "./popupitem"

const UserIcon = styled.button`
    border-radius: 50%;
    margin-left: 10px;
    height: 40px;
    width: 40px;
    background-image: url(${({src}) => src});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: ${({theme}) => theme.colors.primary};
    color: ${({theme}) => theme.colors.inverseText};
    text-align: center;
    border-style: none;
    outline: none;

    transition: transform 400ms cubic-bezier(.455, .03, .515, .955), box-shadow 200ms cubic-bezier(.455, .03, .515, .955);

    &:hover {
        box-shadow: ${({theme}) => theme.hoverShadow};
        transform: translate(0px, -2px);
    }
`
const UserMenu = styled.div`
    position: absolute;
    z-index: 999;
    display: ${props => props.visible? "block" : "none"};
    background-color: ${({theme}) => theme.colors.background};
    box-shadow: ${({theme}) => theme.hoverShadow};
    border-radius: 5px;
    margin-top: 10px;
    margin-left: -100px;
    max-width: 300px;
`
const ProfileWrapper = styled.div`
    position: relative;
    display: inline-block;
    max-height: 40px;
`

const UserProfile = () => {
    const auth = useAuth()
    const wrapper = useRef()
    const [visible,setVisible] = useState(false)

    const handleClickOutside = e => {
        try {
            if(wrapper.current.contains(e.target)){
                return
            }
            setVisible(false)
        } catch (err) {
            handleEventError(err, false, false)
        }
    }

    useEffect(() => {
        if(visible) {
            document.addEventListener("mousedown",handleClickOutside)
        } else {
            document.removeEventListener("mousedown",handleClickOutside)
        }
        return () => document.removeEventListener("mousedown",handleClickOutside) 
    },[visible])

    if(auth.isLoggedIn()) {
        const user = auth.getUser()
        return (
            <ProfileWrapper ref={wrapper}>    
                <UserIcon src={user.photoURL} onClick={e => setVisible(!visible)}>
                    {user.photoURL ? `` : user.displayName.charAt(0)}
                </UserIcon>
                <UserMenu visible={visible}>
                    <PopupItem to="/app/account" first="true" onClick={e => setVisible(false)}>Account</PopupItem>
                    <PopupItem to="/app/integrations" onClick={e => setVisible(false)}>Integrations</PopupItem>
                    <PopupItem to="/app/logout" last="true" onClick={e => setVisible(false)}>Sign out</PopupItem>
                </UserMenu>
            </ProfileWrapper>
        )
    }
    //loading
    return (
        <ProfileWrapper ref={wrapper}>    
            <UserIcon>...</UserIcon>
        </ProfileWrapper>
    )
}

export default UserProfile