import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "styled-components"
import Container from "./container"

const Copyright = styled.div`
    position: relative;
    float: left;   
    @media (max-width: ${({theme}) => theme.breakpoints.small}) {
        float: none;
        text-align: center;
    }     
`
const LinkBlock = styled.div`
    position: relative;
    float: right;

    @media (max-width: ${({theme}) => theme.breakpoints.small}) {
        float: none;
        text-align: center;
    }

`
const StyledLink = styled(Link)`
    padding-left: 10px;
    font-weight: 600;
    color: ${({theme}) => theme.colors.footerText};
    text-decoration: none;
    transition: color 200ms cubic-bezier(.455, .03, .515, .955);

    &:hover {
        color: ${({theme}) => theme.colors.footerInverseText};
    }
`
const StyledFooter = styled.footer`
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: ${({theme}) => theme.colors.footerBackground};
    color: ${({theme}) => theme.colors.footerText};
    font-size: 12px;
`
const Footer = ({ privacy, terms, company }) => (
    <StyledFooter>
        <Container>
            <LinkBlock>
                <StyledLink to={privacy}>Privacy</StyledLink>
                <StyledLink to={terms}>Terms</StyledLink>
            </LinkBlock>

            <Copyright>
                © 2015 - {new Date().getFullYear()} {company}. All Rights Reserved.
            </Copyright>
        </Container>
    </StyledFooter>
)

Footer.propTypes = {
    privacy: PropTypes.string,
    terms: PropTypes.string,
    company: PropTypes.string,
}
  
Footer.defaultProps = {
    privacy: `/privacy`,
    terms: `/terms`,
    company: `Jensa Ventures Inc`,
}

export default Footer
  