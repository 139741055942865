import { Link } from "gatsby"
import styled from "styled-components"

const PopupItem = styled(Link)`
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 0px;
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    color: ${({theme}) => theme.colors.text};
    text-decoration: none;
    min-width: 150px;

    ${props => props.first ? 
        `border-top-left-radius: 5px; border-top-right-radius: 5px;` : 
        `border-top-left-radius: 0px; border-top-right-radius: 0px;`}

    ${props => props.last ? 
        `border-bottom-left-radius: 5px; border-bottom-right-radius: 5px;` : 
        `border-bottom-left-radius: 0px; border-bottom-right-radius: 0px;`}

    transition: background-color 200ms cubic-bezier(.455, .03, .515, .955);  

    &:hover {
        background-color: ${({theme}) => theme.colors.hoverBackground};
    }
`
export default PopupItem