import styled from "styled-components"

export const ButtonWrapper = styled.div`
    margin-top: 5px;
    text-align: ${({align}) => align || "center"};
    ${({paddingTop}) => paddingTop ? `padding-top: ${paddingTop};` : ""}
    ${({paddingBottom}) => paddingBottom ? `padding-bottom: ${paddingBottom};` : ""}
    ${({maxWidth}) => maxWidth ? `max-width: ${maxWidth}` : ""};
    
    ${({breakpoint, responsiveAlign}) => breakpoint ? `@media (max-width: ${breakpoint}) {
        text-align: ${responsiveAlign};
    }`: ""}
    
`

export const OverlapButtonWrapper = styled.div`
    position: relative;
    left: -35px;
    @media (max-width:  ${({theme}) => theme.breakpoints.mobile}) {
        left: 0px;
    }
`
const Button = styled.button`
    padding-right: ${({paddingRight}) => paddingRight || "25px"};
    padding-left: ${({paddingLeft}) => paddingLeft || "25px"};
    padding-top: 9px;
    padding-bottom: 9px;
    border-style: solid;
    border-color:  ${({theme}) => theme.colors.primary};
    border-radius: 25px;
    background-color: ${({secondary, theme}) => secondary ? theme.colors.background : theme.colors.primary};
    color: ${({secondary, theme}) => secondary ? theme.colors.primary : theme.colors.inverseText};
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
    text-decoration: none;
    min-height: ${({minHeight}) => minHeight || "40px"};
    min-width: ${({minWidth}) => minWidth || "150px"};
    outline: none;
    ${({marginTop}) => marginTop ? `margin-top: ${marginTop};` : ""}
    ${({marginBottom}) => marginBottom ? `margin-bottom: ${marginBottom};` : ""}
    ${({marginLeft}) => marginLeft ? `margin-left: ${marginLeft};` : ""}
    ${({marginRight}) => marginRight ? `margin-right: ${marginRight};` : ""}

    transition: transform 400ms cubic-bezier(.455, .03, .515, .955), 
        color 200ms cubic-bezier(.455, .03, .515, .955), 
        background-color 200ms cubic-bezier(.455, .03, .515, .955), 
        border-color 200ms cubic-bezier(.455, .03, .515, .955), 
        box-shadow 200ms cubic-bezier(.455, .03, .515, .955);
    
    &:hover {

        background-color: ${({secondary, theme}) => secondary ?  theme.colors.background : theme.colors.secondary};
        border-color: ${({secondary, theme}) => secondary ? theme.colors.primary : theme.colors.secondary};
        box-shadow: ${({theme}) => theme.hoverShadow};
        ${({noTransform}) => noTransform ? "" : `transform: translate(0px, -2px);`}
    }

    &:disabled {
        background-color: ${({secondary, theme}) => secondary ? theme.colors.disabledBackground : theme.colors.primaryDisabled};
        border-color: ${({secondary, theme}) => secondary ? theme.colors.primaryExtraDisabled : theme.colors.primaryDisabled};
        color: ${({secondary, theme}) => secondary ? theme.colors.disabledText : theme.colors.inverseText};
        box-shadow: none;
        transform: translate(0px, 0px);
    }

    ${({breakpoint, responsiveStyle}) => breakpoint ? `@media (max-width: ${breakpoint}) {
        ${responsiveStyle}
    }` : ""} 

`

export default Button