import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { useMatch } from "@reach/router"

export const MobileMenuItemDivider = styled.div`
    display: none;
    @media (max-width: ${({breakpoint, theme}) => breakpoint || theme.breakpoints.tablet}) {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 25px;
        height: 1px;
        background-color: ${({theme}) => theme.colors.divider};
    }
`

const StyledLink = styled(Link)`
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    color: ${({selected, theme}) => selected ? theme.colors.secondary : theme.colors.text};
    text-decoration: none;
    cursor: ${({selected}) => selected ? "default" : "pointer"} ;

    transition: transform 400ms cubic-bezier(.455, .03, .515, .955), background-color 200ms cubic-bezier(.455, .03, .515, .955);  

    &:hover {
        background-color: ${({selected, theme}) => selected ? "transparent" : theme.colors.hoverBackground};
        ${({selected}) => selected ? "" : "transform: translate(0px, -2px);"}
    }

    @media (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        text-align: center;
        color: ${({selected, theme}) => selected ? theme.colors.secondary : theme.colors.modalText};
        ${({responsiveStyle}) => responsiveStyle}

        &:hover {
            color: ${({selected, theme}) => selected ? theme.colors.secondary : theme.colors.inverseHoverBackground};
            background-color: transparent;
            transform: none;
        }    
    }

`
const LinkWrapper = styled.span`
    ${({display}) => display ? `display: ${display};` : ""}
    margin-left: ${({marginLeft}) => marginLeft || "0px"};
    margin-right: ${({marginRight}) => marginRight || "0px"};
    @media (max-width: ${({breakpoint, theme}) => breakpoint || theme.breakpoints.tablet}) {
        display: flex;
        flex-direction: column;
    }
`

const MenuItem = ({children, to, ...rest}) => {
    return (
        <LinkWrapper {...rest}>
            <StyledLink to={to} selected={!!useMatch(to)}>{children}</StyledLink>
        </LinkWrapper>
    )
}
export default MenuItem