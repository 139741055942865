import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import TopNav, {TopNavLeft, TopNavRight} from "./topnav"
import Footer from "./footer"
import Main from "./main"
import UserProfile from "./userprofile"
import MenuItem, {MobileMenuItemDivider} from "./menuitem"
import Nav from "./nav"
import GoogleButton from "./googlebutton"
import Button from "./button"
import Wrapper from "./wrapper"

import { useAuth } from "../services/auth"
import { useTheme } from "../services/theme"
import * as CONSTANTS from "../services/constant"
import { getLocalStorageItem } from "../services/helper"

const StyledWrapper = styled.div`
    display: flex;
`

const LOGGED_OUT = 0
const LOGGED_IN = 1
const LOADING = 2

const getUserStatus = (auth) => {
    if(auth.isLoggedIn()) {
        return LOGGED_IN
    }

    const cachedStatus = getLocalStorageItem(CONSTANTS.USER_STATUS_CACHE_KEY)  
    if(cachedStatus === CONSTANTS.USER_STATUS_LOGGED_IN) {
        return LOADING
    }

    return LOGGED_OUT
}

const SiteLayout = ({children, hideLogin, hideSignup, hideUserProfile, hideMyWebsites, menu, signup, setSignupAutoRedirect, signupWaiting, setSignupWaiting}) => {
    const auth = useAuth()
    const userStatus = getUserStatus(auth)
    const theme = useTheme()
    const [waiting, setWaiting] = useState(false)
    return (
        <>
            <TopNav>
                <TopNavLeft/>
                <TopNavRight responsivereverse="true" breakpoint={theme.breakpoints.tablet}>
                    {
                        (menu || 
                            (userStatus === LOGGED_OUT && !hideLogin && !(signupWaiting || waiting)) || 
                            (userStatus !== LOGGED_OUT && !hideMyWebsites && !(signupWaiting || waiting))
                        ) &&
                        <Nav>
                            {menu}
                            {
                                (userStatus === LOGGED_OUT && !hideLogin && !(signupWaiting || waiting)) && 
                                <>
                                    {menu ? <MobileMenuItemDivider/> : ""}
                                    <MenuItem to="/app" marginLeft="5px" marginRight="5px">Login</MenuItem>
                                </>
                            }
                            {
                                (userStatus !== LOGGED_OUT && !hideMyWebsites && !(signupWaiting || waiting)) && 
                                <Wrapper display="none" breakpoint={theme.breakpoints.small} responsiveStyle="display:block;">
                                    {menu ? <MobileMenuItemDivider /> : ""}
                                    <MenuItem 
                                        to="/app/websites" 
                                        marginLeft="5px" 
                                        marginRight="5px"
                                    >
                                        View My Websites
                                    </MenuItem>
                                </Wrapper>    
                            }      
                        </Nav>
                    }
                    <StyledWrapper>
                        {
                            (userStatus === LOGGED_OUT && !hideSignup ) && 
                            signup   
                        }
                        {
                            (!signup && !hideSignup ) && 
                            <GoogleButton 
                                show={(userStatus === LOGGED_OUT) || signupWaiting || waiting}
                                marginLeft="5px" 
                                marginRight="0px" 
                                breakpoint={theme.breakpoints.small} 
                                responsiveStyle="display:none;"
                                setAutoRedirect={setSignupAutoRedirect}
                                waiting={setSignupWaiting ? signupWaiting : waiting}
                                setWaiting={setSignupWaiting || setWaiting}
                            >
                                Sign up with Google
                            </GoogleButton>   
                        }
                        {
                            (userStatus !== LOGGED_OUT && !(signupWaiting || waiting)) && 
                            <>
                                {!hideMyWebsites && 
                                    <Wrapper display="inline-block" breakpoint={theme.breakpoints.small} responsiveStyle="display:none;">
                                        <Link to="/app/websites">
                                            <Button 
                                                secondary="true" 
                                                marginLeft="5px" 
                                                minWidth="174px" 
                                            >
                                                View my websites
                                            </Button>
                                        </Link>
                                    </Wrapper>
                                }
                                {!hideUserProfile && <UserProfile />}
                            </>    
                        }
                    </StyledWrapper>
                </TopNavRight>
            </TopNav>
            <Main>
                {children}
            </Main>
            <Footer/>
        </>
    )
}
  export default SiteLayout