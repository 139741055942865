import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "styled-components"
//import Img from "gatsby-image"
//import {graphql, useStaticQuery} from "gatsby"

import logo from "../images/logo.svg"

const StyledImg = styled.img`
    margin-top: 5px;
    margin-bottom: 5px;
    height: 30px;
`
const StyledLink = styled(Link)`
    max-height: 40px;
    margin-right: 10px;
`

const Logo = ({ to }) => {
    /*
    const data = useStaticQuery(
        graphql`
            query {
                logo: file(relativePath: { eq: "logo.png" }) {
                    childImageSharp {
                        fixed(height: 30) {
                            ...GatsbyImageSharpFixed_withWebp_tracedSVG
                        }  
                    }
                }
            }
        `
    ) */

    return (
        <StyledLink to={to}>
            <StyledImg src={logo}/>
        </StyledLink>
    )
}

Logo.propTypes = {
    to: PropTypes.string
}

Logo.defaultProps = {
    to: `/`
}

export default Logo